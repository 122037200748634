import React, { useEffect, useState } from "react";
import iconTimeZone from "../../img/VotesPageActiveVotes_time_icon.svg";
import iconTimeEvent from "../../img/MyVotes_icon_time.svg";
import iconDateEvent from "../../img/MyVotes_data_icon.svg";
import iconCopyLink from "../../img/copyLinkIcon.svg";
import MaterialsVoteQuestion from "../VotesStatusComponents/MaterialsVoteQuestion/MaterialsVoteQuestion";
import * as Events from "../../Api/Events";
import useStartVoteShowButton from "../../utils/CustomHooks/useStartVoteShowButton/useStartVoteShowButton";
import useStartRegShowButton from "../../utils/CustomHooks/useStartRegShowButton/useStartRegShowButton";
import useEndVoteShowButton from "../../utils/CustomHooks/useEndVoteShowButton/useEndVoteShowButton";
import useEndRegShowButton from "../../utils/CustomHooks/useEndRegShowButton/useEndRegShowButton";
import useCurrentUserContext from "../../utils/CustomHooks/useCurrentUserContext/useCurrentUserContext";
import useShowOptionsProlongVoteButton from "../../utils/CustomHooks/useShowOptionsProlongVoteButton/useShowOptionsProlongVoteButton";
import DetailsVoteGeneralInfoOptionProlongVoteModal from "../DetailsVoteGeneralInfoOptionProlongVoteModal/DetailsVoteGeneralInfoOptionProlongVoteModal";
import PreLoaderBlock from "../PreLoaderBlock/PreLoaderBlock";
import { useNavigate } from "react-router-dom";
import { config } from "../../config";
import { Qr } from "../Qr/Qr";
import useOnClickOutsideModal from "../../hooks/useOnClickOutsideModal";
import VotesPageDeleteVoteModal from "../VotesPageDeleteVoteModal/VotesPageDeleteVoteModal";

const DetailsVoteGeneralInfo = (props) => {
  const {
    constants,
    authAs,
    currentEventData,
    requestHelper,
    formatDate,
    formatTime,
  } = props;

  const navigate = useNavigate();
  const { runUpdateDataEvents } = useCurrentUserContext();
  const [labelText, setLabelText] = useState("");
  const [activeModal, setActiveModal] = useState(false);
  const [isShowStartVoteBtn] =
    useStartVoteShowButton(currentEventData);
  const [isShowStartRegBtn] =
    useStartRegShowButton(currentEventData);
  const [isShowEndVoteBtn] =
    useEndVoteShowButton(currentEventData);
  const [isShowEndRegBtn] =
    useEndRegShowButton(currentEventData);
  const [isShowOptionsProlongVote] =
    useShowOptionsProlongVoteButton(currentEventData);
  const [isLoading, setLoading] = useState("");
  const [isActiveOptionProlongModal, setActiveOptionProlongModal] =
    useState(false);
  const [isCopyLinkMessage, setCopyLinkMessage] = useState("");
  const linkJoin = `${config.client_url}/join/${currentEventData?.id}`;
  const styleStatusObj = {
    "Ожидание регистрации": "details-vote-general-info__wait-voiting",
    "Идет регистрация": "details-vote-general-info__registration",
    "Ожидание голосования": "details-vote-general-info__wait-voiting",
    "Идет голосование": "details-vote-general-info__voiting",
    "Регистрация и голосование":
      "details-vote-general-info__registration-and-votes",
    "Голосование завершено": "details-vote-general-info__ended-voiting",
    "Голосование удалено": "details-vote-general-info__ended-voiting",
    "Кворум не достигнут": "details-vote-general-info__quorum_unpresant",
  };

  useOnClickOutsideModal(activeModal, () => setActiveModal(false))

  const findStyleForRegStatus = (regStatus) => {
    for (let key in styleStatusObj) {
      if (regStatus === key) {
        return styleStatusObj[key];
      }
    }
  };

  function onHandleChangeUpdateEvent(typeUpdateTime, hoursNumber) {
    setLoading(typeUpdateTime);
    const body = {
      id: currentEventData?.id,
      bodyRequest: {
        update_datetime_type: typeUpdateTime,
        hours: hoursNumber,
      },
    };
    requestHelper(Events.updateTimeEvent, body)
      .then((res) => {
        if (res.status === "ok") {
          runUpdateDataEvents();
          setLoading("");
        } else {
          console.log(res.text);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      })
      .finally(() => {
        setLoading("");
        closeModal();
        runUpdateDataEvents();
      });
  }

  const onHandleDeleteEvent = (typeUpdateTime, data) => {
    setLoading(typeUpdateTime);
    const body = [data.id];
    requestHelper(Events.deleteEvent, body)
      .then((res) => {
        if (res) {
          // runUpdateDataEvents();
          navigate("/list-votes");
          setLoading("");
        } else {
          console.log(res.text);
        }
      })
      .catch((err) => {
        throw new Error(err.message);
      })
      .finally(() => {
        setLoading("");
        runUpdateDataEvents();
      });
  };

  const closeModal = () => {
    setActiveOptionProlongModal(false);
  };

  const onHandleCopyLInk = (value) => {
    navigator.clipboard
      .writeText(value)
      .then(() => console.log(""))
      .catch((err) => console.error(err));
    setCopyLinkMessage(
      `${constants.DETAILS_VOTE.GENERAL_INFO_LINK_SUCCESS_MESSAGE}`
    );
    cleanLinkJoin();
  };

  const cleanLinkJoin = () => {
    setTimeout(() => {
      setCopyLinkMessage("");
    }, 2000);
  };

  useEffect(() => {
    let isMounted = true;
    if (currentEventData.status === "waiting") {
      if (currentEventData.onButton) {
        isMounted && setLabelText("Ожидание голосования");
      } else {
        isMounted && setLabelText("Ожидание регистрации");
      }
    } else if (currentEventData.status === "registration") {
      if (currentEventData.isVoting) {
        if (currentEventData.onButton) {
          isMounted && setLabelText("Идет голосование");
        } else {
          isMounted && setLabelText("Регистрация и голосование");
        }
      } else {
        isMounted && setLabelText("Идет регистрация");
      }
    } else if (currentEventData.status === "event waiting") {
      isMounted && setLabelText("Ожидание голосования");
    } else if (currentEventData.status === "voting") {
      isMounted && setLabelText("Идет голосование");
    } else if (
      currentEventData.status === "ended" &&
      currentEventData.isDeleted === false
    ) {
      isMounted && setLabelText("Голосование завершено");
    } else if (currentEventData.status === "quorum_unpresant") {
      isMounted && setLabelText("Кворум не достигнут");
    } else if (
      currentEventData.status === "ended" &&
      currentEventData.isDeleted === true
    ) {
      isMounted && setLabelText("Голосование удалено");
    }

    return () => {
      isMounted = false;
    };
  }, [currentEventData]);

  console.log(activeModal)

  return (
    <div className="details-vote-general-info__container">
      <h3 className="details-vote-general-info__title-name-menu">
        {constants.DETAILS_VOTE.GENERAL_INFO_TITLE}
      </h3>
      <h3 className="details-vote-general-info__title-name-vote">
        {currentEventData?.title}
      </h3>
      <div className="details-vote-general-info__main-block">
        <div className="details-vote-general-info__name-org-status-block">
          <p className="details-vote-general-info__name-org">
            {currentEventData?.owner?.title}
          </p>
          <div className="details-vote-general-info__timezone-vote">
            <img
              className="details-vote-general-info__time-icon"
              src={iconTimeZone}
              alt={constants.GENERAL.ALT_ICON}
            />
            <p className="details-vote-general-info__timezone-label">
              (UTC+3) Россия - Москва
            </p>
          </div>
          <div className="details-vote-general-info__status-possible-revote-block">
            <div className="details-vote-general-info__current-status-vote">
              <div className={findStyleForRegStatus(labelText)}>
                <li>{labelText}</li>
              </div>
              <div className="details-vote-general-info__type-vote">
                <li>
                  {currentEventData?.type === "secret"
                    ? `${constants.DETAILS_VOTE.GENERAL_INFO_TYPE_VOTE_CLOSE}`
                    : `${constants.DETAILS_VOTE.GENERAL_INFO_TYPE_VOTE_OPEN}`}
                </li>
              </div>
            </div>
            <div className="details-vote-general-info__possible-revote-cancel-reg">
              <p className="details-vote-general-info__possible-label">
                {constants.DETAILS_VOTE.GENERAL_INFO_POSSIBLE_REVOTING}{" "}
                <span className="details-vote-general-info__possible-value">
                  {currentEventData?.re_voting === true ? "есть" : "нет"}
                </span>
              </p>
              <p className="details-vote-general-info__possible-label">
                {constants.DETAILS_VOTE.GENERAL_INFO_POSSIBLE_CANCEL_REG}{" "}
                <span className="details-vote-general-info__possible-value">
                  {currentEventData?.re_registration === true ? "есть" : "нет"}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="details-vote-general-info__datetime-block">
          {currentEventData?.onButton === false ? (
            <>
              <div className="details-vote-general-info__datetime-event">
                <p className="details-vote-general-info__datetime-event-label">
                  {constants.DETAILS_VOTE.GENERAL_INFO_START_REG}
                </p>
                <div className="details-vote-general-info__datetime-icons-values">
                  <img src={iconDateEvent} alt={constants.GENERAL.ALT_ICON} />
                  <p>
                    {currentEventData?.registration_start_time !== undefined
                      ? formatDate(currentEventData?.registration_start_time)
                      : ""}
                  </p>
                  <img src={iconTimeEvent} alt={constants.GENERAL.ALT_ICON} />
                  <p>
                    {currentEventData?.registration_start_time !== undefined
                      ? formatTime(currentEventData?.registration_start_time)
                      : ""}
                  </p>
                </div>
              </div>
              <div className="details-vote-general-info__datetime-event">
                <p className="details-vote-general-info__datetime-event-label">
                  {constants.DETAILS_VOTE.GENERAL_INFO_END_REG}
                </p>
                <div className="details-vote-general-info__datetime-icons-values">
                  <img src={iconDateEvent} alt={constants.GENERAL.ALT_ICON} />
                  <p>
                    {currentEventData?.registration_end_time !== undefined
                      ? formatDate(currentEventData?.registration_end_time)
                      : ""}
                  </p>
                  <img src={iconTimeEvent} alt={constants.GENERAL.ALT_ICON} />
                  <p>
                    {currentEventData?.registration_end_time !== undefined
                      ? formatTime(currentEventData?.registration_end_time)
                      : ""}
                  </p>
                </div>
              </div>
            </>
          ) : null}
          <div className="details-vote-general-info__datetime-event">
            <p className="details-vote-general-info__datetime-event-label">
              {constants.DETAILS_VOTE.GENERAL_INFO_START_VOTE}
            </p>
            <div className="details-vote-general-info__datetime-icons-values">
              <img src={iconDateEvent} alt={constants.GENERAL.ALT_ICON} />
              <p>
                {currentEventData?.event_start_time !== undefined
                  ? formatDate(currentEventData?.event_start_time)
                  : ""}
              </p>
              <img src={iconTimeEvent} alt={constants.GENERAL.ALT_ICON} />
              <p>
                {currentEventData?.event_start_time !== undefined
                  ? formatTime(currentEventData?.event_start_time)
                  : ""}
              </p>
            </div>
          </div>
          <div className="details-vote-general-info__datetime-event">
            <p className="details-vote-general-info__datetime-event-label">
              {constants.DETAILS_VOTE.GENERAL_INFO_END_VOTE}
            </p>
            <div className="details-vote-general-info__datetime-icons-values">
              <img src={iconDateEvent} alt={constants.GENERAL.ALT_ICON} />
              <p>
                {currentEventData?.event_end_time !== undefined
                  ? formatDate(currentEventData?.event_end_time)
                  : ""}
              </p>
              <img src={iconTimeEvent} alt={constants.GENERAL.ALT_ICON} />
              <p>
                {currentEventData?.event_end_time !== undefined
                  ? formatTime(currentEventData?.event_end_time)
                  : ""}
              </p>
            </div>
          </div>
        </div>
      </div>
      {isShowOptionsProlongVote && (
        <button
          onClick={() => setActiveOptionProlongModal(true)}
          className="details-vote-general-info__option-prolong-btn"
        >
          {constants.DETAILS_VOTE.GENERAL_INFO_OPTION_PROLONG_VOTE_BTN}
        </button>
      )}
      {authAs === "ROLE_ADMIN" ? (
        <div className="details-vote-general-info__materials-vote">
          {currentEventData?.materials?.length !== 0 && (
            <MaterialsVoteQuestion
              currentMaterialsVote={currentEventData?.materials}
              materialsVoteName={constants.VOTES_PAGE.VOTES_PAGE_MATERIALS_VOTE}
            />
          )}
        </div>
      ) : null}
      {authAs === "ROLE_ADMIN" ? (
        <div className="details-vote-general-info__buttons-link-block">
          <div className="details-vote-general-info__buttons-block">
            {isShowStartVoteBtn && (
              <button
                onClick={() => onHandleChangeUpdateEvent("start_voting", 1)}
                className={
                  isLoading === "start_voting"
                    ? "details-vote-general-info__start-end-vote-btn active"
                    : "details-vote-general-info__start-end-vote-btn"
                }
              >
                {isLoading === "start_voting"
                  ? `${constants.AUTH.AUTH_ENTER_BTN_LOADING}`
                  : `${constants.DETAILS_VOTE.GENERAL_INFO_START_VOTE_BTN}`}
              </button>
            )}
            {isShowStartRegBtn && (
              <button
                onClick={() =>
                  onHandleChangeUpdateEvent("start_registration", 1)
                }
                className={
                  isLoading === "start_registration"
                    ? "details-vote-general-info__start-end-vote-btn active"
                    : "details-vote-general-info__start-end-vote-btn"
                }
              >
                {isLoading === "start_registration"
                  ? `${constants.AUTH.AUTH_ENTER_BTN_LOADING}`
                  : `${constants.DETAILS_VOTE.GENERAL_INFO_START_REG_BTN}`}
              </button>
            )}
            {isShowEndVoteBtn && (
              <button
                onClick={() => onHandleChangeUpdateEvent("end_voting", 1)}
                className={
                  isLoading === "end_voting"
                    ? "details-vote-general-info__start-end-reg-btn active"
                    : "details-vote-general-info__start-end-reg-btn"
                }
              >
                {isLoading === "end_voting"
                  ? `${constants.AUTH.AUTH_ENTER_BTN_LOADING}`
                  : `${constants.DETAILS_VOTE.GENERAL_INFO_END_VOTE_BTN}`}
              </button>
            )}
            {isShowEndRegBtn && (
              <button
                onClick={() => onHandleChangeUpdateEvent("end_registration", 1)}
                className={
                  isLoading === "end_registration"
                    ? "details-vote-general-info__start-end-reg-btn active"
                    : "details-vote-general-info__start-end-reg-btn"
                }
              >
                {isLoading === "end_registration"
                  ? `${constants.AUTH.AUTH_ENTER_BTN_LOADING}`
                  : `${constants.DETAILS_VOTE.GENERAL_INFO_END_REG_BTN}`}
              </button>
            )}
            {currentEventData?.isDeleted === false ? (
              <button
                onClick={() => setActiveModal(true)}
                className={
                  isLoading === "delete_vote"
                    ? "details-vote-general-info__delete-vote-btn active"
                    : "details-vote-general-info__delete-vote-btn"
                }
              >
                {isLoading === "delete_vote"
                  ? `${constants.AUTH.AUTH_ENTER_BTN_LOADING}`
                  : `${constants.DETAILS_VOTE.GENERAL_INFO_DELETE_VOTE_BTN}`}
              </button>
            ) : (
              <button className="details-vote-general-info__delete-vote-btn">
                {constants.DETAILS_VOTE.GENERAL_INFO_RESTORE_VOTE_BTN}
              </button>
            )}
          </div>
          {currentEventData?.is_voters_expandable && (
            <div className="details-vote-general-info__link-join-block">
              <p className="details-vote-general-info__link-join-title">
                {constants.DETAILS_VOTE.GENERAL_INFO_LINK_JOIN_TITLE}
              </p>
              <div className="details-vote-general-info__link-icon">
                <a
                  className="details-vote-general-info__link-join"
                  href={linkJoin}
                  target={"_blank"}
                >
                  {linkJoin}
                </a>
                <img
                  onClick={() => onHandleCopyLInk(linkJoin)}
                  className="details-vote-general-info__icon"
                  src={iconCopyLink}
                  alt={constants.ALT_ICON}
                />
              </div>
              <p className="details-vote-general-info__link-join-success-text">
                {isCopyLinkMessage}
              </p>

              <Qr addData={linkJoin} />
            </div>
          )}
        </div>
      ) : null}
      <DetailsVoteGeneralInfoOptionProlongVoteModal
        isActiveOptionProlongModal={isActiveOptionProlongModal}
        onHandleChangeUpdateEvent={onHandleChangeUpdateEvent}
        currentEventData={currentEventData}
        closeModal={closeModal}
        constants={constants}
        isLoading={isLoading}
      />
      <VotesPageDeleteVoteModal
          activeModal={activeModal}
          closeModal={() => setActiveModal(false)}
          deleteSelectedVote={() => onHandleDeleteEvent("delete_vote", currentEventData, setActiveModal(false))}
          constants={constants}
      />
    </div>
  );
};
export default DetailsVoteGeneralInfo;
