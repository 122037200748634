import React, {useEffect, useState} from "react";
import iconCloseModal from "../../img/AddNewVoteQuestuionTypeIconCloseModal.svg";
import iconPlus from "../../img/AddNewVoteQuestuionTypeIconPlus.svg";
import iconBasket from "../../img/AddNewVoteQuestuionTypeIconBasket.svg";
import AddMaterials from "../AddMaterials/AddMaterials";
import {Validation} from "../../utils/Validation/Validation";

const AddNewVoteTypeGrid = (props) => {

    const {
        onCloseModal,
        constants,
        requestHelper,
        questionsList,
        addQuestion,
        questionForEdit,
        changeEditQuestion
    } = props;

    const questionTitle = Validation();
    const [materials, setMaterials] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isEditMode, setEditMode] = useState(false);
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [isCheckboxActive, setCheckboxActive] = useState(false);

    useEffect(() => {
         let isMounted = true;
        if (questionForEdit?.id) {
            isMounted && questionTitle.setValue(questionForEdit?.title);
            isMounted && setMaterials(questionForEdit?.materials?.map(function (item, index) {
                return {...item, id: index + 1}
            }))
            isMounted && setRows(questionForEdit?.options?.rows?.map(function (item, index) {
                return {...item, id: index + 1}
            }));
            isMounted && setColumns(questionForEdit?.options?.columns?.map(function (item, index) {
                return {...item, id: index + 1}
            }));
            isMounted && setEditMode(true);
            isMounted && setCheckboxActive(questionForEdit?.is_required_grid_rows)
        } else {
            isMounted && addRows();
            isMounted && addColumns();
        }
        return () => {
            isMounted = false;
        }
        // eslint-disable-next-line
    }, [questionForEdit])

    function idGenerate(arr) {
        if (arr.length < 1) {
            return 1;
        } else {
            const allIdArray = arr.map((arr) => {
                return arr.id
            });
            return Math.max(...allIdArray) + 1;
        }
    }

    function addRows () {
        const row = {
            id: idGenerate(rows),
            value: ''
        }
        setRows([...rows, row])
    }

    function deleteRows (id) {
        if (rows.length !== 1) {
            const filteredArray = rows.filter(el => el.id !== id)
            setRows(filteredArray)
        }
    }

    function addColumns () {
        const column = {
            id: idGenerate(columns),
            value: ''
        }
        setColumns([...columns, column])
    }

    function deleteColumns (id) {
        if (columns.length !== 1) {
            const filteredArray = columns.filter(el => el.id !== id)
            setColumns(filteredArray)
        }
    }

    function rowInputChange(e, id) {
        const foundedEl = rows.find(el => el.id === id);
        const filteredArray = rows.filter(el => el.id !== id);
        foundedEl.value = e.target.value
        filteredArray.push(foundedEl);
        setRows(filteredArray);
    }

    function columnInputChange(e, id) {
        const foundedEl = columns.find(el => el.id === id);
        const filteredArray = columns.filter(el => el.id !== id);
        foundedEl.value = e.target.value
        filteredArray.push(foundedEl);
        setColumns(filteredArray);
    }

    function rowsValidate() {
        const rowsValidation = () => {
            for (let val of rows) {
                for (let key in val) {
                    if (!val[key]) {
                        return false;
                    }
                }
            }
        }
        const isRowsValid = rowsValidation();
        if (isRowsValid === false) {
            setErrorMessage(constants.ADD_NEW_VOTE.ROWS_ERR);
            return false;
        } else {
            setErrorMessage('');
            return true;
        }
    }

    function columnsValidate() {
        const columnsValidation = () => {
            for (let val of columns) {
                for (let key in val) {
                    if (!val[key]) {
                        return false;
                    }
                }
            }
        }
        const isRowsValid = columnsValidation();
        if (isRowsValid === false) {
            setErrorMessage(constants.ADD_NEW_VOTE.ROWS_ERR);
            return false;
        } else {
            setErrorMessage('');
            return true;
        }
    }

    function materialsValidate(materials) {
        const materialsValidation = () => {
            for (let val of materials) {
                for (let key in val) {
                    if (!val[key]) {
                        return false;
                    }
                }
            }
        }
        const isMaterialsValid = materialsValidation();
        if (isMaterialsValid === false) {
            setErrorMessage(constants.ADD_NEW_VOTE.QUESTION_MATERIALS_ERR);
            return false;
        } else {
            setErrorMessage('');
            return rowsValidate() && columnsValidate();
        }
    }

    function questionValidation(materials) {
        if (questionTitle.value !== '') {
            setErrorMessage('');
            return materialsValidate(materials);
        } else {
            setErrorMessage(constants.ADD_NEW_VOTE.QUESTION_TITLE_ERROR);
            return false;
        }
    }

    function addNewQuestion() {
        const materialsForValidation = [];
        materials.forEach((material) => {
            if (material.type === "link") {
                const data = {
                    title: material.title,
                    type: material.type,
                    value: material.value
                }
                materialsForValidation.push(data);
            } else {
                const data = {
                    title: material.title,
                    type: material.type,
                    value: material.value
                }
                materialsForValidation.push(data);
            }
        })
        const isQuestionValid = questionValidation(materialsForValidation);
        if (isQuestionValid) {
            const question = {
                id: isEditMode ? questionForEdit.id : idGenerate(questionsList),
                template: "grid",
                title: questionTitle.value,
                options: {
                    rows: rows,
                    columns: columns
                },
                materials: materials,
                is_required_grid_rows: isCheckboxActive,
                rules: {
                    pick_eq: -1,
                    pick_lt: -1,
                    pick_gt: -1,
                    pick_le: -1,
                    pick_ge: -1
                }
            }
            if (isEditMode) {
                changeEditQuestion(question);
            } else {
                addQuestion(question);
            }
            onClose();
        }
    }

    function onClose() {
        onCloseModal();
        setErrorMessage('');
        setMaterials([]);
        questionTitle.setValue('');
        setEditMode(false);
    }

    return (
        <div className="add-new-vote-type-grid__container active">
            <div className="add-new-vote-type-grid">
                <div className="add-new-vote-type-grid__title">
                    <h3 className="add-new-vote-type-grid__title-number-question">
                        {!isEditMode ? `${constants.ADD_NEW_VOTE.QUESTION} #${questionsList.length + 1}` : `${constants.ADD_NEW_VOTE.QUESTION} #${questionForEdit?.indexQuestion + 1}`}
                    </h3>
                    <img onClick={onClose} className="add-new-vote-type-grid__title-icon-close" src={iconCloseModal} alt={constants.GENERAL.ALT_ICON} />
                </div>
                <h5 className="add-new-vote-type-grid__title-current-type-question">{constants.ADD_NEW_VOTE.ADD_NEW_VOTE_QUESTION_GRID}</h5>
                <div className="add-new-vote-type-grid__name-question">
                    <label className="add-new-vote-type-grid__name-question-label">
                        {constants.ADD_NEW_VOTE.QUESTION_TYPE_NAME_QUESTION}
                        <span className="add-new-vote__red-star">*</span>
                    </label>
                    <input className="add-new-vote-type-grid__name-question-input"
                        type={'text'}
                        placeholder={constants.ADD_NEW_VOTE.QUESTION_TYPE_NAME_QUESTION_PLACEHOLDER_ENTER_YOUR_QUESTION}
                        value={questionTitle.value}
                        onChange={questionTitle.onChange}
                    />
                </div>
                <div className="add-new-vote-type-grid__types-variants-answer">
                    <div className="add-new-vote-type-grid__types-variants-answer-grid-block">
                        <div className="add-new-vote-type-grid__types-variants-answer-grid">
                            <div className="add-new-vote-type-grid__grid-row">
                                <h4 className="add-new-vote-type-grid__grid-title">{constants.ADD_NEW_VOTE.QUESTION_TYPE_GRID_ROWS}</h4>
                                {rows.sort(function (a, b) {
                                    const aId = a.id;
                                    const bId = b.id;
                                    if (aId < bId)
                                        return -1
                                    if (aId > bId)
                                        return 1
                                    return 0
                                }).map((el) => {
                                    return (
                                        <div key={el.id} className="add-new-vote-type-grid__grid-row-input-block">
                                            <input
                                                className="add-new-vote-type-grid__grid-input-text"
                                                type={"text"}
                                                placeholder={'Введите значение'}
                                                value={el.value}
                                                onChange={(e) => rowInputChange(e, el.id)}/>
                                            <div className="add-new-vote-type-grid__type-input-icons">
                                                <img onClick={addRows} className="add-new-vote-type-grid__type-input-gray-plus" src={iconPlus} alt={constants.GENERAL.ALT_ICON} />
                                                <img onClick={() => deleteRows(el.id)} className="add-new-vote-type-grid__type-input-gray-basket" src={iconBasket} alt={constants.GENERAL.ALT_ICON} />
                                            </div>
                                        </div>
                                    )})}
                            </div>
                            <div className="add-new-vote-type-grid__grid-column">
                                <h4 className="add-new-vote-type-grid__grid-title">{constants.ADD_NEW_VOTE.QUESTION_TYPE_GRID_COLUMNS}</h4>
                                {columns.sort(function (a, b) {
                                    const aId = a.id;
                                    const bId = b.id;
                                    if (aId < bId)
                                        return -1
                                    if (aId > bId)
                                        return 1
                                    return 0
                                }).map((el) => {
                                return (
                                    <div key={el.id} className="add-new-vote-type-grid__grid-column-input-block">
                                        <input className="add-new-vote-type-grid__grid-input-text"
                                               type={"text"}
                                               value={el.value}
                                               onChange={(e) => columnInputChange(e, el.id)}
                                               placeholder={constants.ADD_NEW_VOTE.QUESTION_TYPE_GRID_ENTER_VALUES} />
                                        <div className="add-new-vote-type-grid__type-input-icons">
                                            <img onClick={addColumns} className="add-new-vote-type-grid__type-input-gray-plus" src={iconPlus} alt={constants.GENERAL.ALT_ICON} />
                                            <img onClick={() => deleteColumns(el.id)} className="add-new-vote-type-grid__type-input-gray-basket" src={iconBasket} alt={constants.GENERAL.ALT_ICON} />
                                        </div>
                                    </div>
                                )})}
                            </div>
                        </div>
                        <div className="add-new-vote-type-grid__checkbox">
                            <label className='add-new-vote-type-grid__checkbox_container'>
                                <input
                                       type="checkbox"
                                       checked={isCheckboxActive}
                                       onChange={() => setCheckboxActive(!isCheckboxActive)}
                                />
                                <span className='add-new-vote-type-grid__checkmark' />
                            </label>
                            <p className="add-new-vote-type-grid__label-checkbox">{constants.ADD_NEW_VOTE.QUESTION_TYPE_GRID_NOTE}</p>
                        </div>
                    </div>
                    <div className="add-new-vote-type-grid__add-materials-vote">
                        <AddMaterials
                            constants={constants}
                            materials={materials}
                            setMaterials={setMaterials}
                            isEvent={false}
                            idGenerate={idGenerate}
                            requestHelper={requestHelper}
                            typeQuestion={'grid'}
                        />
                    </div>
                    <p className="add-new-vote-type-ynq__error-message">{errorMessage}</p>
                    <div className="add-new-vote-type-grid__add-button-block">
                        <button onClick={addNewQuestion} className="add-new-vote-type-grid__add-btn">{isEditMode ? constants.ADD_NEW_VOTE.SAVE_CHANGES : constants.ADD_NEW_VOTE.ADD_NEW_VOTE_ADD_QUESTION_BTN}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddNewVoteTypeGrid;
