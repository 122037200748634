import React from "react";

const GroupUsersDeleteModalGroup = (props) => {

    const {
        active,
        closeModal,
        constants,
        isSelectedGroupsForDelete,
        onRemoveGroupsClick
    } = props;

    const filteredArrIsChecked = isSelectedGroupsForDelete.filter(el => el.isChecked === true)

    const correctWordGroup = (value,  words) => {
        let cases = [2, 0, 1, 1, 1, 2];
        return words[
            (value % 100 > 4 && value % 100 < 20) ? 2 : cases[(value % 10 < 5) ? value % 10 : 5]
            ];
    }

    return (
        <div id={'block'} className={active ? 'container__group-users-delete-modal-group active' : 'container__group-users-delete-modal-group'}
             onClick={(e) => e.stopPropagation()}>
            <p className="group-users-delete-modal-group__selector-group">
                Выбрано {filteredArrIsChecked.length} {correctWordGroup(filteredArrIsChecked.length,[`${constants.GROUP_USERS.GROUP_USERS_MODAL_NUMBER_GROUP}`, `${constants.GROUP_USERS.GROUP_USERS_MODAL_NUMBER_GROUPS}`, `${constants.GROUP_USERS.GROUP_USERS_MODAL_NUMBER_SEVERAL_GROUP}`])}
            </p>
            <div className="group-users-delete-modal-group__buttons">
                <button onClick={closeModal} className="group-users-delete-modal-group__cancel-btn">{constants.GROUP_USERS.GROUP_USERS_MODAL_CANCEL}</button>
                {filteredArrIsChecked.length !== 0 &&
                    <button onClick={onRemoveGroupsClick} className="group-users-delete-modal-group__delete-btn">{constants.GROUP_USERS.GROUP_USERS_MODAL_DELETE_SELECTED}</button>}
            </div>
        </div>
    )
}
export default GroupUsersDeleteModalGroup;
