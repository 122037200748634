import React from "react";

const VotesPageDeleteVoteModal = (props) => {

    const {
        activeModal,
        closeModal,
        deleteSelectedVote,
        constants
    } = props;

    return (
        <div className={activeModal ? 'container__group-users-delete-modal-group active' : 'container__group-users-delete-modal-group'}
             onClick={(e) => e.stopPropagation()}
             style={{ height: 'auto'}}
        >
            <p className="group-users-delete-modal-group__selector-group">{constants.VOTES_PAGE.VOTES_PAGE_DELETE_VOTE_MODAL_MESSAGE}</p>
            <div className="group-users-delete-modal-group__buttons">
                <button onClick={deleteSelectedVote} className="group-users-delete-modal-group__cancel-btn">
                    {constants.GROUP_USERS.GROUP_USERS_DELETE_BTN_SMALL}
                </button>
                <button onClick={closeModal} className="group-users-delete-modal-group__cancel-btn">
                    {constants.GROUP_USERS.GROUP_USERS_MODAL_CANCEL}
                </button>
            </div>
        </div>
    )
}
export default VotesPageDeleteVoteModal;
