import React, { useEffect, useState } from 'react';
import './MyVotesBlock.css';
import './VotesPageActiveVotes.css';
import CurrentStatusVote from "../VotesStatusComponents/CurrentStatusVote/CurrentStatusVote";
import StartDateVote from "../VotesStatusComponents/StartDateVote/StartDateVote";
import MaterialsVoteQuestion from "../VotesStatusComponents/MaterialsVoteQuestion/MaterialsVoteQuestion";
import utcIcon from '../../img/VotesPageActiveVotes_time_icon.svg';
import {useLocation} from "react-router-dom";
import deleteIcon from '../../img/AddNewGroupIconBasket.svg';
import editIcon from '../../img/OrganizationsLisеIconEditButton.svg';
import showVote from '../../img/ListUsersIconEye.svg';
import newTabWindow from '../../img/icon-open-new-tab.png';
import VotesPageDeleteVoteModal from "../VotesPageDeleteVoteModal/VotesPageDeleteVoteModal";
import useOnClickOutsideModal from "../../hooks/useOnClickOutsideModal";

const MyVotesBlockForm = (props) => {

    const {
        votesData,
        constants,
        authAs,
        handleCurrentEvents,
        handleDeleteEvents,
        formatDate,
        formatTime,
        utcOffset
    } = props;

    const { pathname } = useLocation();
    const [labelText, setLabelText] = useState('');
    const [activeModal, setActiveModal] = useState(false);
    // const [isVoted, setVoted] = useState(false);
    // const [isNotFullyVoted, setNotFullyVoted] = useState(false);
    // const [hideStatus, setHideStatus] = useState(false);
    useOnClickOutsideModal(activeModal, () => setActiveModal(false))

    useEffect(() => {
        let isMounted = true;

        if (votesData.status === 'waiting') {
            if (votesData.onButton) {
                isMounted && setLabelText('Ожидание голосования');
            } else {
                isMounted && setLabelText('Ожидание регистрации');
            }
        } else if (votesData.status === 'registration') {
            if (votesData.isVoting) {
                if (votesData.onButton) {
                    isMounted && setLabelText('Идет голосование');
                } else {
                    isMounted && setLabelText('Регистрация и голосование');
                }
            } else {
                isMounted && setLabelText('Идет регистрация');
            }
        } else if (votesData.status === 'event waiting') {
            isMounted && setLabelText('Ожидание голосования');
        } else if (votesData.status === 'voting') {
            isMounted && setLabelText('Идет голосование');
        } else if (votesData.status === 'ended' && votesData.isDeleted === false) {
            isMounted && setLabelText('Голосование завершено');
        } else if (votesData.status === 'quorum_unpresant') {
            isMounted && setLabelText('Кворум не достигнут');
        } else if (votesData.status === 'ended' && votesData.isDeleted === true) {
            isMounted && setLabelText('Голосование удалено')
        }

        return () => {
            isMounted = false;
        }

    }, [votesData])

    return (
        <div className={`my-votes-block__vote-form${pathname === '/votes-page' ? ' my-votes-block__vote-form_votes-page' : ''}`}>
            <div className='my-votes-block__container'>
                <div className='my-votes-block__container-title-block'>
                    <h3 onClick={() => handleCurrentEvents(votesData)}
                        className='my-votes-block__container-title-h3'>{votesData?.title}</h3>
                    <h5 className='my-votes-block__container-title-h5'>{votesData?.owner?.title}</h5>
                </div>
                <div className="my-votes-block__content-vote">
                <div className={'status-and-start-reg-start-vote'}>
                    <CurrentStatusVote
                        regStatus={labelText}
                        voteStatus={votesData?.type === 'secret' ? 'Тайное' : 'Открытое'}
                        constants={constants}
                    />
                    <div className='status-and-start-reg-start-vote__reg-vote-date'>
                        <StartDateVote
                            dateTimeDate={formatDate(votesData?.registration_start_time)}
                            dateTimeWatch={formatTime(votesData?.registration_start_time)}
                            title={'Начало регистрации:'}
                        />
                    <div className='reg-vote-date__border-right-mobile'>
                        <StartDateVote
                            dateTimeDate={formatDate(votesData?.event_start_time)}
                            dateTimeWatch={formatTime(votesData?.event_start_time)}
                            title={'Начало голосования:'}
                        />
                    </div>
                    </div>
                    <div className='status-and-start-reg-start-vote__add-border-left'>
                        <div className='my-votes-block__utc-container'>
                            <img alt='Иконка часового пояса' src={utcIcon} className='my-votes-block__utc-icon' />
                            <p className='my-votes-block__utc-value'>(UTC+3) Россия - Москва</p>
                        </div>
                        {votesData?.materials?.length !== 0 && <MaterialsVoteQuestion
                            currentMaterialsVote={votesData.materials}
                            materialsVoteName={constants?.VOTES_PAGE.VOTES_PAGE_MATERIALS_VOTE}
                        />}
                    </div>
                </div>
                    <div className="my-votes-block__buttons-block">
                        {authAs === 'ROLE_SUPER_ADMIN' ? <div onClick={() => handleCurrentEvents(votesData)} className="my-votes-block__btn-block-show">
                            <img className="my-votes-block__show-vote-icon" src={showVote} alt={showVote}/>
                            <p className='my-votes-block__show-label'>{constants.VOTES_PAGE.VOTES_PAGE_SHOW_VOTE_BTN}</p>
                        </div> : null}
                        {authAs === 'ROLE_ADMIN' &&
                            <>
                                <div onClick={() => handleCurrentEvents(votesData)}
                                     className="my-votes-block__btn-block-edit">
                                    <img className="my-votes-block__edit-icon" src={showVote} alt={'Иконка'}/>
                                    <p className='my-votes-block__edit-label'>{constants.VOTES_PAGE.VOTES_PAGE_SHOW_VOTE_BTN}</p>
                                </div>
                                {/*Здесь кнопка для открытия вкладки в новом окне, возможно когда-то пригодиться*/}
                                {/*<div>*/}
                                {/*     className="my-votes-block__btn-block-new-tab">*/}
                                {/*    <img className="my-votes-block__edit-icon" src={newTabWindow} alt={'Иконка'}/>*/}
                                {/*    <p className='my-votes-block__new-tab-label'>{constants.VOTES_PAGE.VOTES_PAGE_OPEN_NEW_WINDOW_BTN}</p>*/}
                                {/*</div>*/}
                            </>
                        }
                        {authAs === 'ROLE_ADMIN' && votesData?.isDeleted === false ?
                            <div onClick={() => setActiveModal(true)}
                                 className="my-votes-block__btn-block-delete">
                                <img className="my-votes-block__delete-icon" src={deleteIcon} alt={'Иконка'}/>
                                <p className='my-votes-block__delete-label'>{constants.VOTES_PAGE.VOTES_PAGE_DELETE_VOTE_BNT}</p>
                            </div> : null}
                    </div>
                </div>
            </div>
            <VotesPageDeleteVoteModal
                activeModal={activeModal}
                closeModal={() => setActiveModal(false)}
                deleteSelectedVote={() => handleDeleteEvents(votesData, setActiveModal(false))}
                constants={constants}
            />
        </div>
    )
}
export default MyVotesBlockForm;