import {useEffect, useState} from "react";

function useEndVoteShowButton(currentEventData) {

    const [value, setValue] = useState(false);

    useEffect(() => {
        let isMount = true;

        if (currentEventData?.status === 'voting' && currentEventData?.isVoting === true) {
            isMount && setValue(true)
        } else if (currentEventData?.status === 'registration' && currentEventData?.isVoting === true) {
            isMount && setValue(true)
        } else {
            isMount && setValue(false)
        }

        return () => {
            isMount = false;
        }
    },[currentEventData])

    return [value];
}

export default useEndVoteShowButton;