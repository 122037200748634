import {constants} from "../Localization/Ru/constants";

export const sortTypeItems = {
    activeVotes: [
        {name: constants.FILTER_SORT_BLOCK.FILTER_SORT_BLOCK_EVENT_NAME, sortPropertyDec: '-eventName', sortPropertyInc: 'eventName', activeBtnDec: false, activeBtnInc: false},
        {name: constants.FILTER_SORT_BLOCK.FILTER_SORT_BLOCK_ORG_NAME, sortPropertyDec: '-orgName', sortPropertyInc: 'orgName', activeBtnDec: false, activeBtnInc: false},
        {name: constants.FILTER_SORT_BLOCK.FILTER_SORT_BLOCK_TIME_START_REG, sortPropertyDec: '-startReg', sortPropertyInc: 'startReg', activeBtnDec: false, activeBtnInc: false},
        {name: constants.FILTER_SORT_BLOCK.FILTER_SORT_BLOCK_TIME_START_VOTE, sortPropertyDec: '-startVote', sortPropertyInc: 'startVote', activeBtnDec: false, activeBtnInc: false},
        {name: constants.FILTER_SORT_BLOCK.FILTER_SORT_BLOCK_TIME_END_REG, sortPropertyDec: '-endReg', sortPropertyInc: 'endReg', activeBtnDec: false, activeBtnInc: false},
        {name: constants.FILTER_SORT_BLOCK.FILTER_SORT_BLOCK_TIME_END_VOTE, sortPropertyDec: '-endVote', sortPropertyInc: 'endVote', activeBtnDec: true, activeBtnInc: false}
    ],
    archiveVotes: [
        {name: constants.FILTER_SORT_BLOCK.FILTER_SORT_BLOCK_EVENT_NAME, sortPropertyDec: '-eventName', sortPropertyInc: 'eventName', activeBtnDec: false, activeBtnInc: false},
        {name: constants.FILTER_SORT_BLOCK.FILTER_SORT_BLOCK_ORG_NAME, sortPropertyDec: '-orgName', sortPropertyInc: 'orgName', activeBtnDec: false, activeBtnInc: false},
        {name: constants.FILTER_SORT_BLOCK.FILTER_SORT_BLOCK_TIME_START_REG, sortPropertyDec: '-startReg', sortPropertyInc: 'startReg', activeBtnDec: false, activeBtnInc: false},
        {name: constants.FILTER_SORT_BLOCK.FILTER_SORT_BLOCK_TIME_START_VOTE, sortPropertyDec: '-startVote', sortPropertyInc: 'startVote', activeBtnDec: false, activeBtnInc: false},
        {name: constants.FILTER_SORT_BLOCK.FILTER_SORT_BLOCK_TIME_END_REG, sortPropertyDec: '-endReg', sortPropertyInc: 'endReg', activeBtnDec: false, activeBtnInc: false},
        {name: constants.FILTER_SORT_BLOCK.FILTER_SORT_BLOCK_TIME_END_VOTE, sortPropertyDec: '-endVote', sortPropertyInc: 'endVote', activeBtnDec: true, activeBtnInc: false}
    ]
}

export const findTypeSortEvent = (events, sortTypeItems) => {
    const foundTypeDec = sortTypeItems.find(el => el.activeBtnDec);
    const foundTypeInc = sortTypeItems.find(el => el.activeBtnInc);
    if (foundTypeDec) {
        return templateSortDec(foundTypeDec.sortPropertyDec, events);
    }
    if (foundTypeInc) {
        return templateSortInc(foundTypeInc.sortPropertyInc, events);
    }
}

const templateSortDec = (typeSort, events) => {
    switch (typeSort) {
        case '-eventName' :
            return events.sort((a, b) =>
                b.status.length > a.status.length ? 1 : -1);
        case '-orgName' :
            return events.sort((a, b) =>
                b.owner.title > a.owner.title ? 1 : -1);
        case '-startReg' :
            return events.sort((a, b) =>
                b.registration_start_time > a.registration_start_time ? 1 : -1);
        case '-startVote' :
            return events.sort((a, b) =>
                b.event_start_time > a.event_start_time ? 1 : -1);
        case '-endReg' :
            return events.sort((a, b) =>
                b.registration_end_time > a.registration_end_time ? 1 : -1);
        case '-endVote' :
            return events.sort((a, b) =>
                b.event_end_time > a.event_end_time ? 1 : -1);
        default: return events.sort((a, b) =>
            b.event_end_time > a.event_end_time ? 1 : -1)
    }
}

const templateSortInc = (typeSort, events) => {
    switch (typeSort) {
        case 'eventName' :
            return events.sort((a, b) =>
                a.status.length > b.status.length ? 1 : -1);
        case 'orgName' :
            return events.sort((a, b) =>
                a.owner.title > b.owner.title ? 1 : -1);
        case 'startReg' :
            return events.sort((a, b) =>
                a.registration_start_time > b.registration_start_time ? 1 : -1);
        case 'startVote' :
            return events.sort((a, b) =>
                a.event_start_time > b.event_start_time ? 1 : -1);
        case 'endReg' :
            return events.sort((a, b) =>
                a.registration_end_time > b.registration_end_time ? 1 : -1);
        case 'endVote' :
            return events.sort((a, b) =>
                a.event_end_time > b.event_end_time ? 1 : -1);
        default: return events.sort((a, b) =>
            a.event_end_time > b.event_end_time ? 1 : -1)
    }
}


