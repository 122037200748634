import React, {useState} from "react";
import * as Organizations from '../../Api/Organizations';
import { setCorrectNameProtocol } from "../../utils/SetCorrectNameProtocol/setCorrectNameProtocol";

const AddMaterials = (props) => {

    const {
        constants,
        materials,
        idGenerate,
        setMaterials,
        isEvent,
        requestHelper,
        typeQuestion
    } = props;

    const [isActiveOptions, setActiveOptions] = useState(null);

    function addEmptyMaterial() {
        const material = {
            id: idGenerate(materials),
            title: "",
            type: "link",
            value: "",
            selectedFileName: constants.ADD_NEW_ORG.ADD_NEW_ORG_SELECT_FILE,
            isFileSelected: false
        }
        setMaterials([...materials, material]);
    }

    function deleteMaterial(id) {
        const filteredArray = materials.filter(el => el.id !== id);
        setMaterials(filteredArray);
    }

    function changeMaterialType(id, type, isEvent, value) {
        if (!isEvent) {
            const foundedMaterial = materials.find(el => el.id === id);
            const filteredArray = materials.filter(el => el.id !== id);
            if (foundedMaterial.type === type && foundedMaterial.value === value) {
               return;
            }
            foundedMaterial.type = type;
            foundedMaterial.value = '';
            foundedMaterial.selectedFileName = constants.ADD_NEW_ORG.ADD_NEW_ORG_SELECT_FILE;
            foundedMaterial.isFileSelected = false;
            filteredArray.push(foundedMaterial);
            setMaterials(filteredArray);
        }
    }

    function linkInputChange(e, id) {
        const foundedEl = materials.find(el => el.id === id);
        const filteredArray = materials.filter(el => el.id !== id);
        foundedEl.value = e.target.value
        filteredArray.push(foundedEl);
        setMaterials(filteredArray);
    }

    function titleInputChange(e, id) {
        const foundedEl = materials.find(el => el.id === id);
        const filteredArray = materials.filter(el => el.id !== id);
        foundedEl.title = e.target.value
        filteredArray.push(foundedEl);
        setMaterials(filteredArray);
    }

    function changeDocLink(id, fileName, link) {
        const foundedEl = materials.find(el => el.id === id);
        const filteredArray = materials.filter(el => el.id !== id);
        foundedEl.isFileSelected = true;
        foundedEl.selectedFileName = fileName;
        foundedEl.value = link;
        filteredArray.push(foundedEl);
        setMaterials(filteredArray);
    }

    function handleOpenOptions(id) {
        if(id !== isActiveOptions) {
            setActiveOptions(id);
        } else {
            setActiveOptions(null);
        }
    }

    function onSelectFileHandler(e, id) {
        const files = e.target.files;
        const file = files[0];
        if (file !== undefined) {
            const fileName = file.name.replace(/ /g, '--');
            const random = new Date().getTime();
            const fileNameForSave = `${random + '_' + fileName}`;
            const data = new FormData();
            data.append(file.name, file, fileNameForSave);
            const uploadData = {
                data: data
            }
            requestHelper(Organizations.uploadProtocol, uploadData)
                .then((res) => {
                    changeDocLink(id, file.name, res[0]);
                })
                .catch((err) => {
                    throw new Error(err.message);
                })
        }
    }

    return (
        <div className="add-materials">
            <div className="add-materials__button-container" onClick={addEmptyMaterial}>
                <div className="add-materials__button-icon" />
                <p className="add-materials__button-text">{isEvent ? constants.ADD_NEW_VOTE.ADD_MATERIALS_VOTE_TITLE : constants.ADD_NEW_VOTE.ADD_MATERIALS_VOTE_TITLE_QUESTION_TYPE}</p>
            </div>
            {materials.sort(function (a, b) {
                const aId = a.id;
                const bId = b.id;
                if (aId < bId)
                    return -1
                if (aId > bId)
                    return 1
                return 0
            }).map((material) => (
                <div className="add-materials__main-container" key={material.id}>
                    <div className="add-materials__heading-container">
                        <div className="add-materials__heading-input-container">
                            <input
                                className="add-materials__heading-input"
                                type="text"
                                placeholder={constants.ADD_NEW_VOTE.ADD_MATERIALS_VOTE_PLACEHOLDER}
                                value={material.title}
                                onChange={(e) => titleInputChange(e, material.id)}
                            />
                            <p className="add-materials__heading-input-red-star">*</p>
                        </div>
                        <div className="add-materials__delete-container" onClick={() => deleteMaterial(material.id)}>
                            <p className="add-materials__delete-text">{constants.ADD_NEW_VOTE.ADD_NEW_VOTE_DELETE_BTN_TABLE}</p>
                            <div className="add-materials__delete-icon" />
                        </div>
                    </div>
                    <div className="add-materials__select-container">
                        <div className="add-materials__select-types-container" onClick={() => handleOpenOptions(material.id)}>
                            <p className="add-materials__select-types-value">{material.type === "link" ? constants.ADD_NEW_VOTE.ADD_MATERIALS_VOTE_LINK : constants.ADD_NEW_VOTE.ADD_MATERIALS_VOTE_DOCUMENT}</p>
                            <div className="add-materials__select-types-arrow" />
                            {isActiveOptions === material.id &&
                                <div className="add-materials__types-container" id={material.id}>
                                    <div className="add-materials__type-container"
                                         onClick={() => changeMaterialType(material.id, "link", isEvent, material.value)}>
                                        <p className="add-materials__type">{constants.ADD_NEW_VOTE.ADD_MATERIALS_VOTE_LINK}</p>
                                    </div>
                                    <div className="add-materials__type-container"
                                         onClick={() => changeMaterialType(material.id, "doc", isEvent, material.value)}>
                                        <p className="add-materials__type">{constants.ADD_NEW_VOTE.ADD_MATERIALS_VOTE_DOCUMENT}</p>
                                    </div>
                                </div>
                            }
                        </div>
                        {material.type === "link" ? (
                            <input
                                className="add-materials__link-input"
                                type="text"
                                placeholder="https://example.com"
                                value={material.value}
                                onChange={(e) => linkInputChange(e, material.id)}
                            />
                        ) : (
                            <div className="add-materials__file-add-container">
                                <input
                                    className="add-materials__file-add-input"
                                    id={material.id + "file" + typeQuestion}
                                    type="file"
                                    onChange={(e) => onSelectFileHandler(e, material.id)}
                                />
                                <label htmlFor={material.id + "file" + typeQuestion} className="add-materials__file-add-input-container">
                                    <div className="add-materials__file-add-input-file-name-container">
                                        <p className={`add-materials__file-add-input-file-name-text ${(material.isFileSelected || material.value) && 'add-materials__file-add-input-file-name-text_selected'}`}>{material.selectedFileName ? material.selectedFileName : setCorrectNameProtocol(material.value) ?? ''}</p>
                                    </div>
                                    <div className="add-materials__file-add-input-button">
                                        <p className='add-materials__file-add-input-button-text'>{constants.ORG_SETTINGS.LOAD_BUTTON}</p>
                                    </div>
                                </label>
                                <div className="add-materials__success-container">
                                    {material.isFileSelected && (
                                        <>
                                            <div className="add-materials__success-icon" />
                                            <p className="add-materials__success-label">{constants.ORG_SETTINGS.LOAD_READY}</p>
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="add-materials__delete-container-mobile" onClick={() => deleteMaterial(material.id)}>
                        <p className="add-materials__delete-text-mobile">{constants.ADD_NEW_VOTE.ADD_NEW_VOTE_DELETE_BTN_TABLE}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default AddMaterials;
